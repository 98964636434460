<template>
  <div class="is-boxed has-animations">
    <div class="body-wrap">
      <div class="container">
        <div class="site-header-inner">
          <div class="brand header-brand">
            <span class="fs-5 ms-1 text-muted">Beta</span>
            <span class="m-0">
              <a :href="SITE_URL">
                <img class="header-logo-image" :src="require('@/assets/images/logo.webp')" alt="Logo" width="230"
                  height="32" />
              </a>
            </span>
          </div>
        </div>
      </div>

      <main>
        <section class="hero">
          <div class="container">
            <div class="hero-inner">
              <div class="hero-copy">
                <h1 class="hero-title mt-0">{{ $t("homepage.title") }}</h1>
                <p class="hero-paragraph">
                  {{ $t("homepage.slogan1") }}
                </p>
                <div class="hero-cta">
                  <a class="button button-primary" :href="`/${this.$i18n.locale}/login`">{{ $t("homepage.nowearn")
                    }}</a><a class="button" :href="`/${this.$i18n.locale}/login`">{{
                    $t("homepage.getcredit")
                    }}</a>
                </div>
              </div>

              <div class="hero w-100">
                <!--begin::Swapper-->
                <div v-if="messageok==1" class="py-5">
											<!--begin::Information-->
											<div class="d-flex align-items-center rounded py-5 px-5 bg-light-success">
												<!--begin::Icon-->
												<!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
												<span class="svg-icon svg-icon-3x svg-icon-success me-5">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"></rect>
														<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"></rect>
													</svg>
												</span>
												<!--end::Svg Icon-->
												<!--end::Icon-->
												<!--begin::Description-->
												<div class="text-gray-700 fw-bold fs-6">{{$t("support.messageok")}}</div>
												<!--end::Description-->
											</div>
											<!--end::Information-->
										</div>
                <!--end::Swapper-->
                <form action="#" class="login-form" v-on:submit.prevent="supportadd">
                  <!--begin::Input group-->
                  <div class="input-group input-group-sm mb-5 border-0">
                    <span class="input-group-text" id="basic-addon1">
                      <!--begin::Svg Icon | path: assets/media/icons/duotune/communication/com002.svg-->
                      <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3"
                            d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
                            fill="black" />
                          <path
                            d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
                            fill="black" />
                        </svg></span>
                      <!--end::Svg Icon-->
                    </span>
                    <input v-model="email" type="email" class="form-control form-control-sm text-white fs-5"
                      :placeholder="$t('useraut.email')" :aria-label="$t('useraut.email')"
                      aria-describedby="basic-addon1" />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="input-group input-group-sm mb-5 border-0">
                    <span class="input-group-text" id="basic-addon1">
                      <!--begin::Svg Icon | path: assets/media/icons/duotune/communication/com003.svg-->
                      <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3"
                            d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                            fill="black" />
                          <path
                            d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                            fill="black" />
                        </svg></span>
                      <!--end::Svg Icon-->
                    </span>
                    <input v-model="subject" type="text" class="form-control form-control-sm text-white fs-5"
                      :placeholder="$t('support.subject')" :aria-label="$t('support.subject')"
                      aria-describedby="basic-addon1" />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="input-group input-group-sm">
                    <span class="input-group-text">
                      <!--begin::Svg Icon | path: assets/media/icons/duotune/text/txt002.svg-->
                      <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M17 11H7C6.4 11 6 10.6 6 10V9C6 8.4 6.4 8 7 8H17C17.6 8 18 8.4 18 9V10C18 10.6 17.6 11 17 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                            fill="black" />
                          <path opacity="0.3"
                            d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM18 20V19C18 18.4 17.6 18 17 18H7C6.4 18 6 18.4 6 19V20C6 20.6 6.4 21 7 21H17C17.6 21 18 20.6 18 20Z"
                            fill="black" />
                        </svg></span>
                      <!--end::Svg Icon-->
                    </span>
                    <textarea :placeholder="$t('support.message')" class="form-control form-control-sm text-white fs-5"
                      v-model="message" aria-label="With textarea" rows="3" cols="50"></textarea>
                  </div>
                  <!--end::Input group-->
                  <div class="icon d-flex align-items-center justify-content-center mt-10">
                    <vue-recaptcha @verify="onVerify" class="mb-3" @expired="onExpired"
                      sitekey="6LfpOt0dAAAAAMwVBKdOFgZ1Gsqnm1O6N87Dsorb" :loadRecaptchaScript="true"></vue-recaptcha>
                  </div>
                  <div v-if="buttonDisabled === 0" class="form-group d-flex justify-content-center">
                    <div class="w-100 d-flex justify-content-center">
                      <button type="submit" class="button button-primary">
                        {{$t("support.submit")}}
                      </button>
                    </div>
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <div v-if="buttonDisabled === 1" class="w-100 d-flex justify-content-center">
                      <button type="button" class="button button-primary">
                        <div class="spinner-border spinner-border-sm" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer class="site-footer">
        <div class="container">
          <div class="site-footer-inner">
            <div class="brand footer-brand"></div>
            <ul class="footer-links list-reset">
              <li>
                <a href="/Whitepaper.pdf" target="_blank">Whitepaper</a>
              </li>
              <li class="ms-4">
                <a :href="`/${this.$i18n.locale}/about`">{{
                  $t("homepage.aboutus")
                  }}</a>
              </li>
              <li class="ms-4">
                <a href="#">{{ $t("homepage.faqs") }}</a>
              </li>
              <li class="ms-4">
                <a :href="`/${this.$i18n.locale}/support`">{{
                  $t("homepage.support")
                  }}</a>
              </li>
              <li class="ms-4">
                <language-switcher class="someClass"></language-switcher>
              </li>
            </ul>
            <ul class="footer-social-links list-reset">
              <li>
                <a href="https://twitter.com/altcoincase?t=50pwg2CIJPgsbYbPYEah0A&s=09">
                  <span class="screen-reader-text">Twitter</span>
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                      fill="#0270D7" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/altcoincase/">
                  <span class="screen-reader-text">Linkedin</span>
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                      fill="#0270D7" />
                  </svg>
                </a>
              </li>
            </ul>
            <div class="footer-copyright text-light">
              <a href="#">
                <img class="header-logo-image" :src="require('@/assets/images/altcoincase-icon.webp')" alt="Logo"
                  width="35" height="34" />
              </a>
              <span class="text-light ms-2 mt-2">&copy; 2021 Altcoincase,
                {{ $t("homepage.allrightsreserved") }}</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
  import DataService from "../services/DataService";
  import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
  import VueRecaptcha from "vue-recaptcha";
  export default {
    components: { "vue-recaptcha": VueRecaptcha, LanguageSwitcher },
    name: "Support",

    metaInfo: function () {
      return {
        title: this.$t("metatag.titledefault"),
        titleTemplate: "%s | " + this.$t("metatag.titleDetailHome"),
        meta: [
          { name: "description", content: this.$t("metatag.description") },
          { name: "keywords", content: this.$t("metatag.keywords") },
        ],
      };
    },
    data() {
      return {
        SITE_URL: process.env.VUE_APP_SITE_URL,
        recaptchaStatus: false,
        message: "",
        email: "",
        subject: "",
        messageok:0,
        buttonDisabled:0
      };
    },
    methods: {
      userActivity() {
        DataService.userActivity().then((response) => {
          this.term = response.data.ip;
        });
        this.browser = navigator.userAgent;
        this.browserLang = navigator.language;
        this.platform = navigator.platform;
      },
      async createToken(
        data0 = "null",
        data1 = "null",
        data2 = "null",
        data3 = "null",
        data4 = "null",
        data5 = "null",
        data6 = "null",
        data7 = "null",
        data8 = "null",
        data9 = "null",
        data10 = "null"
      ) {
        var send = [];
        await DataService.veriSifrele([
          data0,
          data1,
          data2,
          data3,
          data4,
          data5,
          data6,
          data7,
          data8,
          data9,
          data10,
        ]).then((response) => {
          send.push(response.data);
        });
        return send;
      },
      async supportadd() {
        if (!this.recaptchaStatus) {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning("", this.$t('useraut.recaptcha'));
          return true;
        }
        if (this.email != "" && this.subject != "") {
          this.buttonDisabled = 1;
          console.log(this.email, this.subject, this.message);
          DataService.addSupport({
            token: await this.createToken(this.email, this.subject, this.message),
            activity: await this.createToken(
              this.term,
              this.browser,
              this.browserLang,
              this.platform
            ),
          })
            .then((response) => {
              this.buttonDisabled = 0;
              if (!response.data.error && response.data.status == "ok") {
                this.messageok=1;
                this.email ="";
                this.subject ="";
                this.message ="";
              } else {
                this.$vToastify.setSettings({
                  position: "bottom-right",
                  theme: "light",
                });
                this.$vToastify.warning("", this.$t("message." + response.data.error));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning("", this.$t('useraut.blankfield'));
        }
      },
      onVerify(response) {
        this.recaptchaStatus = true;
        return response;
      },
      onExpired: function () {
        console.log("Expired");
        this.recaptchaStatus = false;
      },
      resetRecaptcha() {
        this.$refs.recaptcha.reset(); // Direct call reset method
      },
      onSubmit: function () { },
    },
    mounted() {
    },
  };
</script>

<style lang="" scoped src="../assets/anasayfa/css/style.css"></style>



<style scoped>
  h2,
  .h2 {
    line-height: 22px;
  }

  .input-group-text {
    background-color: #1d2026;
    border: 1px solid #31353e;
  }

  .form-control {
    background-color: #1d2026;
    border: 1px solid #31353e;
  }

  .form-control:focus {
    background-color: #ffffff;
    color: #1d2026 !important;
    border: 1px solid #31353e;
  }
</style>