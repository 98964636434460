<template>
  <div>
    <Support></Support>
  </div>
</template>

<script>
// @ is an alias to /src
import Support from '@/components/Support.vue'

export default {
  name: 'SupportView',
  components: {
    Support,
  }
}
</script>
